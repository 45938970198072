import React from 'react';
import { Redirect } from 'react-router-dom';

const ExpireEmail = React.lazy(() => import('views/OnbordingFlow/ExpireEmail'));
const Home = React.lazy(() => import('views/Home'));
const SignUp = React.lazy(() => import('views/SignUp'));
const Login = React.lazy(() => import('views/Login'));
const Onbording = React.lazy(() => import('views/OnbordingFlow'));
const OnboardingSuccess = React.lazy(
  () => import('views/OnbordingFlow/Success'),
);
const Purchase = React.lazy(() => import('views/PurchaseFlow/Purchase'));
const PurchaseSuccess = React.lazy(() => import('views/PurchaseFlow/Success'));
const CoolOffPeriodWarning = React.lazy(
  () => import('views/PurchaseFlow/CooloffPeriodWarning'),
);
const Privacy = React.lazy(() => import('views/LegalTextPages/Privacy'));

const FundUpdate = React.lazy(() => import('views/Portal/FundUpdate'));
const CustomUpdate = React.lazy(() => import('views/Portal/CustomUpdate'));
const SyndicateUpdate = React.lazy(
  () => import('views/Portal/SyndicateUpdate'),
);
const ExitStatement = React.lazy(() => import('views/Portal/ExitStatement'));
const RequestAccess = React.lazy(() => import('views/RequestAccess'));
const ForgetPassword = React.lazy(() => import('views/ForgetPassword'));
const ResetPassword = React.lazy(() => import('views/ResetPassword'));
const VerifyEmail = React.lazy(() => import('views/OnbordingFlow/VerifyEmail'));
const SummaryDashboard = React.lazy(
  () => import('views/Portal/SummaryDashboard'),
);
const Account = React.lazy(() => import('views/Portal/AccountPage'));
const InvestmentDashboard = React.lazy(
  () => import('views/Portal/InvestmentDashboard'),
);
const SyndicateInvestmentsDashboard = React.lazy(
  () => import('views/Portal/SyndicateInvestmentsDashboard'),
);
const Guide = React.lazy(() => import('views/Guide'));
const AssestmentError = React.lazy(
  () => import('views/PurchaseFlow/ErrorMessage'),
);
const GuideDetail = React.lazy(() => import('views/Guide/Detail'));
const KycPending = React.lazy(() => import('views/PurchaseFlow/KycPending'));
const SubscriptionExpired = React.lazy(
  () => import('views/PurchaseFlow/ExpiredSubscription'),
);
const TermsAndCondition = React.lazy(
  () => import('views/LegalTextPages/TermsAndConditions'),
);
const Deregulation = React.lazy(
  () => import('views/LegalTextPages/Deregulation/Deregulation'),
);
const PaymentUnsuccessful = React.lazy(
  () => import('views/PurchaseFlow/PaymentUnsuccessful'),
);
const CashBalance = React.lazy(() => import('views/Portal/CashBalance'));
const ShareHoldings = React.lazy(
  () => import('views/Portal/PortfolioHoldings'),
);
const FundOnboarding = React.lazy(() => import('views/FundOnboarding'));
const RedirectToPurchase = React.lazy(
  () => import('views/PurchaseFlow/RedirectToPurchase'),
);
const AdviserOnboarding = React.lazy(() => import('views/Adviser/Signup'));
const AdviserDashboard = React.lazy(() => import('views/Adviser/Dashboard'));
const InvestorCashBalance = React.lazy(
  () => import('views/Adviser/InvestorCashBalance'),
);
const InviteSuccess = React.lazy(
  () => import('views/Adviser/InviteInvestorSuccess'),
);
const InviteAdviser = React.lazy(() => import('views/Adviser/InviteInvestor'));
const OrganisationDashboard = React.lazy(
  () => import('views/Organisation/Dashboard'),
);
const AdminDelegateEntry = React.lazy(() => import('views/AdminDelegateEntry'));
const InvestorTypeChange = React.lazy(() => import('views/InvestorTypeChange'));

const commonRoutes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: Home,
  },
  {
    path: '/verify-email',
    exact: true,
    name: 'VerifyEmail',
    private: true,
    component: VerifyEmail,
  },
  {
    path: '/expire-email',
    exact: true,
    name: 'ExpireEmail',
    component: ExpireEmail,
  },
  {
    path: '/sign-up',
    exact: true,
    name: 'SignUp',
    component: SignUp,
    isLoggedOutOnlyRoute: true,
  },
  {
    path: '/login',
    exact: true,
    name: 'Login',
    component: Login,
    isLoggedOutOnlyRoute: true,
  },
  {
    path: '/onboarding',
    exact: true,
    private: true,
    name: 'Onbording',
    component: Onbording,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/success',
    exact: true,
    private: true,
    name: 'OnboardingSuccess',
    component: OnboardingSuccess,
  },
  {
    path: '/purchase',
    exact: true,
    private: true,
    name: 'Purchase',
    component: Purchase,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/apply',
    exact: true,
    private: true,
    name: 'Apply',
    component: RedirectToPurchase,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/purchase-success',
    exact: true,
    private: true,
    name: 'Purchase',
    component: PurchaseSuccess,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/cool-off-period-warning',
    exact: true,
    private: true,
    name: 'Purchase',
    component: CoolOffPeriodWarning,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/privacy-policy',
    exact: true,
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/forget-password',
    exact: true,
    name: 'ForgetPassword',
    component: ForgetPassword,
    isLoggedOutOnlyRoute: true,
  },
  {
    path: '/reset-password',
    exact: true,
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/request-access',
    exact: true,
    name: 'RequestAccess',
    component: RequestAccess,
  },
  {
    path: '/signup',
    exact: true,
    name: 'SignUp',
    component: SignUp,
    isLoggedOutOnlyRoute: true,
  },
  {
    path: '/portal',
    private: true,
    exact: true,
    name: 'Dashboard',
    component: SummaryDashboard,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/user/dashboard',
    private: true,
    exact: true,
    name: 'Dashboard',
    component: () => <Redirect to="/portal" />,
  },
  {
    path: '/user/cash-balance/:firmId',
    private: true,
    exact: true,
    name: 'Cash Balance',
    component: CashBalance,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/user/portfolio-holdings',
    private: true,
    exact: true,
    name: 'My underlying holdings',
    component: ShareHoldings,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/user/account',
    private: true,
    exact: true,
    name: 'Account',
    component: Account,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/user/investment/:id',
    private: true,
    exact: true,
    name: 'InvestmentDashboard',
    component: InvestmentDashboard,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/user/syndicate-investments/:groupingKey',
    private: true,
    exact: true,
    name: 'SyndicateInvestmentsDashboard',
    component: SyndicateInvestmentsDashboard,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/user/fund-update/:reportingId/:investmentId',
    private: true,
    exact: true,
    name: 'Fund Update',
    component: FundUpdate,
  },
  {
    path: '/user/custom-update/:customUpdateId/:investmentId?',
    private: true,
    exact: true,
    name: 'Custom Update',
    component: CustomUpdate,
  },
  {
    path: '/user/exit-statement/:exitStatementId/:investmentId?',
    private: true,
    exact: true,
    name: 'Exit Update',
    component: ExitStatement,
  },
  // the route below is not actively used, having been replaced by the one above. The
  // The difference is that the one below has a fundId in the path, but this is not
  // actually required. Because some investors may have old emails that link to this
  // route, we will keep it here for some time.
  {
    path: '/user/fund-update/:reportingId/:fundId/:investmentId',
    private: true,
    exact: true,
    name: 'Fund Update',
    component: FundUpdate,
  },
  {
    path: '/user/syndicate-update/:reportingId/:groupingKey',
    private: true,
    exact: true,
    name: 'Syndicate Update',
    component: SyndicateUpdate,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/guides',
    exact: true,
    name: 'Guide',
    component: Guide,
    hideForSubDomains: true,
  },
  {
    path: '/guides/:id/:itemId',
    exact: true,
    name: 'GuideDetail',
    component: GuideDetail,
  },
  {
    path: '/purchase-failure',
    private: true,
    exact: true,
    name: 'Purchase',
    component: AssestmentError,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/kyc-pending',
    private: true,
    exact: true,
    name: 'Purchase',
    component: KycPending,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/subscription-expired',
    private: true,
    exact: true,
    name: 'Subscription Expired',
    component: SubscriptionExpired,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/payment-unsuccessful',
    private: true,
    exact: true,
    name: 'Payment Unsuccessful',
    component: PaymentUnsuccessful,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/terms-conditions',
    exact: true,
    name: 'Terms and conditions',
    component: TermsAndCondition,
  },
  {
    path: '/deregulation',
    exact: true,
    name: 'Deregulation',
    component: Deregulation,
  },
  // This is used by funds to send links to investors so it takes them
  // to the purchase flow for a specific fund. We have a very similar
  // link /apply, but this does not specify a fund. We should combine
  // this link and /apply in the future.
  {
    path: '/fund-onboarding/:fundSlug',
    exact: true,
    private: true,
    name: 'FundOnboarding',
    component: FundOnboarding,
    onlyAllowedInSubDomain: true,
    isInvestorOnlyRoute: true,
  },
  {
    path: '/adviser-onboarding',
    exact: true,
    private: true,
    name: 'AdviserOnboarding',
    isAdviserOnlyRoute: true,
    component: AdviserOnboarding,
  },
  {
    path: '/invite-investor',
    exact: true,
    name: 'InviteAdviser',
    isAdviserOnlyRoute: true,
    private: true,
    component: InviteAdviser,
  },
  {
    path: '/adviser-dashboard',
    exact: true,
    private: true,
    name: 'AdviserDashboard',
    isAdviserOnlyRoute: true,
    component: AdviserDashboard,
  },
  {
    path: '/adviser/investor-cash-balance/:investorId/:firmId',
    exact: true,
    private: true,
    name: 'InvestorCashBalance',
    isAdviserOnlyRoute: true,
    component: InvestorCashBalance,
  },
  {
    path: '/invite-success',
    exact: true,
    name: 'invite-success',
    isAdviserOnlyRoute: true,
    component: InviteSuccess,
    private: true,
  },
  {
    path: '/org-dashboard',
    exact: true,
    name: 'OrganisationDashboard',
    component: OrganisationDashboard,
    isOrganisationOnlyRoute: true,
    private: true,
  },
  {
    path: '/admin-delegate-entry/:token',
    exact: true,
    name: 'AdminDelegateEntry',
    component: AdminDelegateEntry,
  },
  {
    path: '/investor-type-change',
    name: 'InvestorTypeChange',
    component: InvestorTypeChange,
    exact: true,
    isInvestorOnlyRoute: true,
    private: true,
  },
  {
    path: '/investor-type-change/:investorType',
    name: 'InvestorTypeChange',
    component: InvestorTypeChange,
    exact: true,
    isInvestorOnlyRoute: true,
    private: true,
  },
];

export default commonRoutes;
